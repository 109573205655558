export default function animateHomeUspBackgroundVideo(video: Ref<HTMLVideoElement | undefined>) {
  const { $gsap } = useNuxtApp()
  const ctx = ref<gsap.Context | null>(null)
  const progress = ref(0)

  const progressLinear = ref(0)

  watch(progress, (value) => {
    if (!video.value) {
      return
    }
    if (video.value.duration && video.value.duration) {
      video.value.currentTime = video.value.duration * value
    }
  })

  onMounted(() => {
    ctx.value = $gsap.context(() => {
      // console.log('GSAP CONTEXT', progress.value)
      $gsap.to(progress, {
        value: 1,
        scrollTrigger: {
          trigger: '#videoContainer',
          start: '50% 50%',
          end: '+=400%',
          scrub: 1,
          pin: true,
          onUpdate: (scrollTrigger) => {
            progressLinear.value = scrollTrigger.progress
          },
        },
      })
    })
  })

  return {
    progress: progressLinear,
  }
}
